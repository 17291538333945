html {
  background-color: #817f75;
  color: #fff;
  font-size: 1em;
  line-height: 1.4;

  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}

body {
  font-family: "linotype-vectora", Arial, Helvetica, sans-serif;
  font-weight: 300;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

::-moz-selection {
  background: #F7EACA;
  color: #432818;
  text-shadow: none;
}

::selection {
  background: #F7EACA;
  color: #432818;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hidden,
[hidden] {
  display: none !important;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  /* 1 */
}

.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix::before,
.clearfix::after {
  content: " ";
  display: table;
}

.clearfix::after {
  clear: both;
}

#left-col {
  background: url("../img/bg-left.jpg");
  background-size: cover;
  background-position: center;
}

#left-col #symbol {
  display: block;
  width: 80.625px;
  height: auto;
  margin-top: 5vh;
}

#left-col #logo {
  display: block;
  width: 140.9375px;
  height: auto;
  margin-bottom: 5vh;
  margin-left: 40.3125px;
}

#nav {
  background-color: #fbf9ef;
  padding-top: 4vh;
  padding-bottom: 2vh;
  font-weight: 400;

  -webkit-font-smoothing: initial;
  -moz-font-smoothing: initial;
  -o-font-smoothing: initial;
}

#nav a.active {
  color: black;
}

#nav a {
  color: #999;
  white-space: nowrap;
  padding-left: 0.5rem;
}

.navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

#content {
  height: 85vh;
  background-size: cover;
  background-position: center;
}

#content {
  background-color: #817f75;
}
#content.default-bg {
  background-color: #1d4e89;
}
#content.bg-1  {
  background-image: url('../img/bg-right4.jpg');
}
#content.bg-2 {
  background-image: url('../img/bg-right1.jpg');
}
#content.bg-3 {
  background-image: url('../img/bg-right2.jpg');
}
#content.bg-4 {
  background-image: url('../img/bg-right3.jpg');
}
#content[data-bg-remove] {
  background-image: none !important;
}

.preload-images {
  width: 0;
  height: 0;
  overflow: hidden;
  background: url('../img/bg-right1.jpg'),
              url('../img/bg-right2.jpg'),
              url('../img/bg-right3.jpg'),
              url('../img/bg-right4.jpg');
}

content {
  display: block;
  padding: 18vh 2.5rem 0;
  font-size: 1.15em;
  line-height: 1.6;
  letter-spacing: .5px;
}

content #profile p {
  font-size: .925em;
}

content .subnav {
  display: block;
  margin-top: 6vh;
}

content .subnav,
content .smaller {
  font-size: .85em;
}

content p,
content *,
content a {
  color: #faf6e3;
}

content a {
  color: #faf6e3;
  text-decoration: underline;
  cursor: pointer;
}

content a:hover {
  color: white;
}

.vertical-line {
  border-left: 1px solid #432817;
  margin-left: 40.3125px;
}

.nav-link {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.btn-primary {
  background-color: #432818;
  border-color: #432818;
}

.btn-primary:hover {
  background-color: #331E12;
  border-color: #331E12;
}

.btn-primary:not(:disabled):not(.disabled):active, 
.btn-primary:focus {
  background-color: #2A190F;
  border-color: #2A190F;
}

.container,
.container-fluid {
  overflow-x: hidden;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl,
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-right: 8px;
  padding-left: 8px;
}

.row {
  margin-right: -8px;
  margin-left: -8px;
}

.navbar-collapse {
  transition: height 0.2s ease-out 0s;
}

button:focus {
  outline: initial;
}

.list-group-item {
  background-color: transparent;
  padding: 0.55rem 1.25rem;
  font-size: 1rem;
}

.list-group-two-col {
  -moz-column-count: 2;
  -moz-column-gap: 60px;
  -webkit-column-count: 2;
  -webkit-column-gap: 60px;
  column-count: 2;
  column-gap: 60px;
}

@media (max-width: 767px) {
  #left-col {
    height: 38vh;
    background-color: #fdf0d0;
    background-size: 300px;
    background-repeat: no-repeat;
    background-position: right top;
  }
  #left-col #mobile-symbol {
    width: 200px;
    margin-left: 5vw;
    margin-top: 5vh;
    height: auto;
  }
  #nav {
    padding: 0;
  }
  .navbar {
    padding: 0.5rem 3rem;
  }
  content {
    padding: 6vh 2.5rem 0;
  }
  #content {
    height: auto;
    padding-bottom: 8vh;
  }
}

@media (max-device-width: 767px) and (orientation: landscape) {
  #left-col {
    background-position: right center;
  }
}

@media (min-width: 768px) {
  .full-height {
    height: 100vh;
  }
  #nav {
    height: 15vh;
  }

  #nav a,
  .navbar-light .navbar-nav .nav-link {
    padding-right: 0rem;
    padding-left: 2rem;
  }
}

@media print,
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 1.25dppx),
  (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */
}

@media print {
  *,
  *::before,
  *::after {
    background: #fff !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: " (" attr(href) ")";
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

