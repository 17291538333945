html {
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  background-color: #817f75;
  font-size: 1em;
  line-height: 1.4;
}

body {
  font-family: linotype-vectora, Arial, Helvetica, sans-serif;
  font-weight: 300;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

::selection {
  color: #432818;
  text-shadow: none;
  background: #f7eaca;
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hidden, [hidden] {
  display: none !important;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only.focusable:active, .sr-only.focusable:focus {
  clip: auto;
  white-space: inherit;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

#left-col {
  background: url("bg-left.6df1ceb1.jpg") center / cover;
}

#left-col #symbol {
  width: 80.625px;
  height: auto;
  margin-top: 5vh;
  display: block;
}

#left-col #logo {
  width: 140.938px;
  height: auto;
  margin-bottom: 5vh;
  margin-left: 40.3125px;
  display: block;
}

#nav {
  -webkit-font-smoothing: initial;
  -moz-font-smoothing: initial;
  -o-font-smoothing: initial;
  background-color: #fbf9ef;
  padding-top: 4vh;
  padding-bottom: 2vh;
  font-weight: 400;
}

#nav a.active {
  color: #000;
}

#nav a {
  color: #999;
  white-space: nowrap;
  padding-left: .5rem;
}

.navbar-nav {
  flex-direction: row;
}

#content {
  background-color: #817f75;
  background-position: center;
  background-size: cover;
  height: 85vh;
}

#content.default-bg {
  background-color: #1d4e89;
}

#content.bg-1 {
  background-image: url("bg-right4.ae30e0ca.jpg");
}

#content.bg-2 {
  background-image: url("bg-right1.4d30100e.jpg");
}

#content.bg-3 {
  background-image: url("bg-right2.515cc0fd.jpg");
}

#content.bg-4 {
  background-image: url("bg-right3.b58e0c79.jpg");
}

#content[data-bg-remove] {
  background-image: none !important;
}

.preload-images {
  background: url("bg-right1.4d30100e.jpg"), url("bg-right2.515cc0fd.jpg"), url("bg-right3.b58e0c79.jpg"), url("bg-right4.ae30e0ca.jpg");
  width: 0;
  height: 0;
  overflow: hidden;
}

content {
  letter-spacing: .5px;
  padding: 18vh 2.5rem 0;
  font-size: 1.15em;
  line-height: 1.6;
  display: block;
}

content #profile p {
  font-size: .925em;
}

content .subnav {
  margin-top: 6vh;
  display: block;
}

content .subnav, content .smaller {
  font-size: .85em;
}

content p, content *, content a {
  color: #faf6e3;
}

content a {
  color: #faf6e3;
  cursor: pointer;
  text-decoration: underline;
}

content a:hover {
  color: #fff;
}

.vertical-line {
  border-left: 1px solid #432817;
  margin-left: 40.3125px;
}

.nav-link {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.btn-primary {
  background-color: #432818;
  border-color: #432818;
}

.btn-primary:hover {
  background-color: #331e12;
  border-color: #331e12;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:focus {
  background-color: #2a190f;
  border-color: #2a190f;
}

.container, .container-fluid {
  overflow-x: hidden;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-left: 8px;
  padding-right: 8px;
}

.row {
  margin-left: -8px;
  margin-right: -8px;
}

.navbar-collapse {
  transition: height .2s ease-out;
}

button:focus {
  outline: initial;
}

.list-group-item {
  background-color: #0000;
  padding: .55rem 1.25rem;
  font-size: 1rem;
}

.list-group-two-col {
  -moz-column-count: 2;
  -moz-column-gap: 60px;
  -webkit-column-count: 2;
  -webkit-column-gap: 60px;
  column-count: 2;
  column-gap: 60px;
}

@media (width <= 767px) {
  #left-col {
    background-color: #fdf0d0;
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-size: 300px;
    height: 38vh;
  }

  #left-col #mobile-symbol {
    width: 200px;
    height: auto;
    margin-top: 5vh;
    margin-left: 5vw;
  }

  #nav {
    padding: 0;
  }

  .navbar {
    padding: .5rem 3rem;
  }

  content {
    padding: 6vh 2.5rem 0;
  }

  #content {
    height: auto;
    padding-bottom: 8vh;
  }
}

@media (device-width <= 767px) and (orientation: landscape) {
  #left-col {
    background-position: 100%;
  }
}

@media (width >= 768px) {
  .full-height {
    height: 100vh;
  }

  #nav {
    height: 15vh;
  }

  #nav a, .navbar-light .navbar-nav .nav-link {
    padding-left: 2rem;
    padding-right: 0;
  }
}

@media print {
  *, :before, :after {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: #fff !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

/*# sourceMappingURL=index.8f32d18f.css.map */
